<template>
  <div class="guest-booking-page">
    <GuestBookingPage />
  </div>
</template>

<script>
// @ is an alias to /src
import GuestBookingPage from '@/modules/guests/pages/GuestBookingPage.vue';

export default {
  components: {
    GuestBookingPage,
  },
  layout: 'AppFullPage',
};
</script>
